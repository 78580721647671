import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import '../Assets/Styles/navbar.css';

function Navbar() {
    const [activeLink, setActiveLink] = useState('/');
    const location = useLocation();
    const hamburgerRef = React.useRef();
    const navMenuRef = React.useRef();
    const headerRef = React.useRef();

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location.pathname]);

    function closeMenu() {
        hamburgerRef.current.classList.remove("active");
        navMenuRef.current.classList.remove("active");
    }

    function mobileMenu() {
        hamburgerRef.current.classList.toggle("active");
        navMenuRef.current.classList.toggle("active");
    }

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (hamburgerRef.current && !headerRef.current.contains(e.target)) {
                hamburgerRef.current.classList.remove("active");
                navMenuRef.current.classList.remove("active");
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="header" ref={headerRef}>
            <nav className="navbar">
                <ul className="nav-menu" ref={navMenuRef}>
                    <li className="nav-item">
                        <Link
                            to="/"
                            className={`nav-link ${activeLink === '/' ? 'active' : ''}`}
                            onClick={closeMenu}
                        >
                            <h6>Home</h6>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="/services"
                            className={`nav-link ${activeLink.startsWith('/services') ? 'active' : ''}`}
                            onClick={closeMenu}
                        >
                            <h6>Services</h6>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="/about-us"
                            className={`nav-link ${activeLink === '/about-us' ? 'active' : ''}`}
                            onClick={closeMenu}
                        >
                            <h6>About</h6>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link
                            to="/contact-us"
                            className={`nav-link ${activeLink === '/contact-us' ? 'active' : ''}`}
                            onClick={closeMenu}
                        >
                            <h6>Contact</h6>
                        </Link>
                    </li>
                </ul>
                <div className="hamburger" ref={hamburgerRef} onClick={mobileMenu}>
                    <span className="bar" />
                    <span className="bar" />
                    <span className="bar" />
                </div>
            </nav>
        </div>
    );
}

export default Navbar;
