import React from "react";
import "../Assets/Styles/AboutPage.css";
import Office2 from "../Assets/Images/AboutUs/Office_image_2.jpg";
import QualityPolicyImg from "../Assets/Images/AboutUs/quality_policy_image.webp";
import { IoDiamond } from "react-icons/io5";
import { FaBullseye, FaEye  } from "react-icons/fa6";

function AboutUs() {
  return (
    <div className="about-us-main-container">
      <div className="about-us-content-main">
        <div className="about-us-header">
          <h1>About Us</h1>
        </div><br/>
        <div className="d-flex row">
          <div className="about-page-left-container col-md-6 col-sm-12">
            <div className="about-company-main">
              <div className="about-us-subcontent-header">
                <h2>About Company</h2>
              </div>
              <div className="about-us-content">
                <p>
                  To become a leading provider of comprehensive procurement
                  solutions, industrial services, and manpower supple,
                  delivering exceptional value to our clients while upholding
                  the highest standards of quality, reliability, and integrity.
                </p>
              </div>
            </div>
            <div className="our-strength-main">
              <div className="about-us-subcontent-header">
                <h2>Our Strength</h2>
              </div>
              <div className="about-us-content">
                <p>
                  We believe in team management and that's our way to Success.
                  We our team are here to serve you at 24x7. Our Certified
                  Professionals are experienced in Design, Estimation, Surveying
                  Construction, Inspection (QA/QC), Testing, Pre-Commissioning
                  Process, Turn-around, Shutdown, Maintenance, Refurbishment,
                  Transmission, Fabrications and Quality, Quantity & HSE Audits.<br/>
                  We have certified QA/QC Managers, Supervisors, and Various
                  Inspectors with experience with major clients like Aramco,
                  Sabic ,SEC,etc to meet your requirements at any time.
                  Presently we have more than 90 employees deployed in various
                  Projects. From its beginning hasSilver Creek occupied
                  particular place within the private sector of Saudi Arabia.
                </p>
              </div>
            </div>
          </div>
          <div className="about-page-right-container col-md-6 col-sm-12">
                <div className="about-carousel-image-wrapper">
                  <img
                    className="d-block w-100 about-carousel-image"
                    src={Office2}
                    alt="Second slide"
                  />
                </div>
          </div>
        </div>
        <div className="row" style={{marginTop:"4rem"}}>
          <div className="col-sm-12 col-md-4 d-flex justify-content-center">
            <div className="vision-container-main">
              <div className="vision-icon"> <FaEye /></div>
              <div className="vision-info">
                <div className="vision-title"><h2>Our Vision</h2></div>
                <div className="vision-content-main">To be recognised as a trusted partner in the industry, renowned
              for our commitment to excellence, innovation, and customer
              satisfaction. We aspire to continuously expand our capabilities
              and enhance our offerings to meet the evolving needs of our
              clients and exceed their expectations.</div>
              </div>
            </div>
          </div>
          
          <div className="col-sm-12 col-md-4 d-flex justify-content-center" style={{marginTop:"2rem"}}>
            <div className="vision-container-main">
              <div className="vision-icon"> <FaBullseye  /></div>
              <div className="vision-info">
                <div className="vision-title"><h2>Our Mission</h2></div>
                <div className="vision-content-main">Our mission is to provide end-to-end procurement solutions,
              industrial services, and manpower supply with a focus on
              efficiency, professionalism, and sustainability through strategic
              partnerships, cutting-edge technogolies, and a dedicated team of
              experts.</div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 d-flex justify-content-center vision-container-content" style={{marginTop:"4rem"}}>
            <div className="vision-container-main">
              <div className="vision-icon"> <IoDiamond  /></div>
              <div className="vision-info">
                <div className="vision-title"><h2>Our Values</h2></div>
                <div className="vision-content-main">We build our business in honesty and integrity and deals with our
                society with high standards business ethics.
              clients and communities we serve.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-policy-main py-3">
        <div className="about-policy-header">
          <h1>Policies</h1>
        </div><br/>
        <div className="quality-policy-main">
          <div className="about-policy-subheader" style={{marginBottom:"1rem"}}>
            <h2>Quality Policy</h2>
          </div>
          <div className="about-policy-content row d-flex" >
            <p className="col-md-7 col-sm-12 px-4 py-2">
              Silver Creek and Inspector's as a leading Industrial services
              company in all aspects of engineering has adopted a quality policy
              to ensure the effective implementation of international standards
              across the board including project sites and the offices. <br/>The
              objective of the policy to continually improve overall performance
              so that customers can be provided services that ensure safety,
              reliability and value in a cost effective manner. <br/>
              Resources International quality department is responsible for the
              identification, remediable action of quality issues and in-house
              training of the staff. The quality manual, procedures and
              plans,which developed, are the inherent component of Resources
              International operating philosophy.The quality commitment can only
              be achieved through the right training, techniques, supervision
              and positive attitude. <br/>Therefore, the company's quality management
              system is a must in training of all employees; ensuring that the
              policy is understood, implemented and maintained at all levels of
              the organization operation activities.
            </p>
            <div className="about-page-right-container col-md-5 col-sm-12" style={{paddingTop:"0rem"}}>
                <div className="about-carousel-policy-image-wrapper">
                  <img
                    className="d-block w-90 about-carousel-image"
                    style={{height:"23rem", margin: "0 auto"}}
                    src={QualityPolicyImg}
                    alt="Second slide"
                  />
                </div>
          </div>
          </div>
        </div><br/>
        <div className="hse-policy-main">
          <div className="about-policy-subheader">
            <h2>HSE Policy</h2>
          </div>
          <div className="about-policy-content">
            <span>
              Silver Creek developed and implemented HSE management system to
              ensure:{" "}
            </span>
            <ul>
              <li>The organization and planning for HSE effectively.</li>
              <li>
                Safe places, system of work and suitable procedures are provided
                and well maintained trained staff and sub-contractors are
                competent to performs their task.{" "}
              </li>
              <li>
                Risks from our activities are assessed and either eliminated or
                reduced to acceptable levels comply with all applicable HSE
                laws, regulation and standards.
              </li>
              <li>
                Follow good industrial practices where the legislation is
                inadequate or does not exist.
              </li>
              <li>
                Preparation of emergency plans during the execution of the
                projects.
              </li>
              <li>
                An audit program, which monitors and verifies Resource
                International's performance.
              </li>
              <li>
                The HSE policy is reviewed periodically to ensure its
                effectiveness.
              </li>
              <li>
                Everyone In Resources International has individual authority,
                responsibility and accountability for the safety of themselves
                and others, and an obligation to actively participate in
                promoting an effective HSE culture.
              </li>
              <li>
                At Resources International sta ffat all levels is encouraged to
                stop any unsafe activity that may results in any human or
                environmental accident.
              </li>
              <li>
                The management at Resources International geared up to support
                human and cultural heritage.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
