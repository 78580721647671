import React from 'react'
import '../../Assets/Styles/header.css'
import Navbar from '../../Components/Navbar'
import logo from '../../Assets/Images/SilverCreekLogo.jpeg'
function Header() {
  return (
    <div className='header-main-container pt-3 px-3 '>
        <div className='header-logo-container'>
            <div className='header-logo-image'><img src={logo} alt="Logo Image Here"></img></div>
            <h3 className='header-logo-text'><span style={{color:"#2b3579"}}>SILVER</span><span style={{color:"#2c2e35"}}> CREEK</span></h3>
        </div>
        <div className='header-navbar-container mt-2'>
            <Navbar/>
        </div>
    </div>
  )
}

export default Header