import React, { useEffect, useState } from "react";
import "../Assets/Styles/ContactPage.css";
import Broucher from "../Assets/broucher.pdf";
import emailjs from "@emailjs/browser";
import { FaDownload } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaMapMarkerAlt, FaEnvelope } from "react-icons/fa";

function ContactUs() {
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => emailjs.init("WTyA4pe7r4BXFVcII"), []);
  const sendEmail = async (e) => {
    e.preventDefault();
    const serviceId = "service_m7iufzs";
    const templateId = "template_silCre";
    try {
      await emailjs.send(serviceId, templateId, {
        from_name: contactName,
        from_email: contactEmail,
        message: message,
        contact_number: contactNumber,
      });
      setContactEmail("");
      setContactName("");
      setContactNumber("");
      setMessage("");
      toast.success("Message sent successfully");
    } catch (error) {
      toast.error("Message could not be sent!");
    }
  };
  return (
    <div>
      <div className="contact-main-container">
        <div className="contact-main-title px-5 pt-1 col-md-12 mb-4">
          <h1>Contact Us</h1>
        </div>
        <div className="contact-main-content d-flex">
          <div className="contact-main-content-div d-flex ">
            <div className="contact-office-location col-md-6 col-sm-12">
              <iframe
              title="Silver Creek Map"
                style={{
                  borderTopLeftRadius: "2rem",
                  borderBottomLeftRadius: "2rem",
                }}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3554.62328547729!2d49.65268677612404!3d27.01046405588161!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e35a1004c91b711%3A0x65fef7c4ab620a3b!2sSilver%20Creek%20Trading%20%26%20Contracting%20Co.!5e0!3m2!1sen!2sin!4v1723522865724!5m2!1sen!2sin"
                width="100%"
                height="100%"
                allowfullscreen="true"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
            <div className="contact-main-form px-5 py-2 col-md-4 col-sm-12 mb-4">
              <div className="contact-us-subcontent-header">
                <h2 className="col-md-12 pt-4 px-2">Let's Get In Touch!</h2>
                <p className="text-muted">
                  Send us a message and we will get back to you as soon as
                  possible!
                </p>
              </div>
              <div className=" px-2">
                <form onSubmit={sendEmail}>
                  <div class="form-group">
                    <div className="input-container">
                      <input
                        value={contactName}
                        type="text"
                        className="form-control"
                        id="contactName"
                        onChange={(e) => setContactName(e.target.value)}
                        required
                        placeholder=" "
                      />
                      <label
                        htmlFor="contactName"
                        className="placeholder-label"
                      >
                        Enter Name<span className="red-asterisk">*</span>
                      </label>
                    </div>
                  </div>
                  <div class="form-group">
                    <div className="input-container">
                      <input
                        value={contactEmail}
                        type="email"
                        class="form-control"
                        required
                        id="contactEmail"
                        aria-describedby="emailHelp"
                        onChange={(e) => setContactEmail(e.target.value)}
                        placeholder=" "
                      />
                      <label
                        htmlFor="contactEmail"
                        className="placeholder-label"
                      >
                        Enter Email<span className="red-asterisk">*</span>
                      </label>
                    </div>
                  </div>
                  <div class="form-group">
                    <div className="input-container">
                      <input
                        value={contactNumber}
                        type="telephone"
                        class="form-control"
                        id="contactNumber"
                        onChange={(e) => setContactNumber(e.target.value)}
                        placeholder=" "
                      />
                      <label
                        htmlFor="contactNumber"
                        className="placeholder-label"
                      >
                        Enter Contact Number
                      </label>
                    </div>
                  </div>
                  <div class="form-group">
                    <div className="input-container">
                      <textarea
                        value={message}
                        class="form-control"
                        required
                        id="contactMessage"
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder=" "
                      />
                      <label
                        htmlFor="contactMessage"
                        className="placeholder-label"
                      >
                        Enter Message<span className="red-asterisk">*</span>
                      </label>
                    </div>
                  </div>
                  <button
                    type="submit"
                    class="contact-submit-button btn btn-primary"
                  >
                    Submit{" "}
                  </button>
                </form>
              </div>
              <ToastContainer />
            </div>
          </div>
        </div>
        <div className="text-center contact-details-container">
          <div className="row">
            <div class="col-sm-12 col-md-6">
              <div class="features-item">
                <div class="features-icon">
                  <FaMapMarkerAlt />
                </div>
                <div class="features-info">
                  <h4 class="title">VISIT US AT</h4>
                  <p>
                    Mussaed Ibn Abdul Aziz Street, Al Safat Dist., Al Jubail -
                    35514, KSA
                  </p>
                </div>
              </div>
            </div>

            {/* <div class="col-sm-12 col-md-4">
          <div class="features-item">
            <div class="features-icon">
              <FaPhoneAlt/>
            </div>
            <div class="features-info">
              <h4 class="title">GIVE US A CALL</h4>
              <p>+966 50 444 5347</p>
            </div>
          </div>
        </div> */}

            <div class="col-sm-12 col-md-6">
              <div class="features-item">
                <div class="features-icon">
                  <FaEnvelope />
                </div>
                <div class="features-info">
                  <h4 class="title">SEND AN EMAIL</h4>
                  <p>info@silvercreeksa.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-brochure-container p-5 d-flex">
        <div className="contact-brochure-text col-md-7 col-sm-12">
          <h4>Want to Know More About Us?</h4>
        </div>
        <div className="contact-brochure-button col-md-5 col-sm-12">
          <a
            href={Broucher}
            download="Silver Creek Broucher"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button class="btn btn-primary contact-brochure-button-text">
              Download Broucher &nbsp;
              <FaDownload />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
