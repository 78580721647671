import React, { useEffect, useState } from "react";
import "../Assets/Styles/ServicesGrid.css";
import civil_construction from "../Assets/Images/ServiceGrid/civil_construction.jpg";
import hvac_service from "../Assets/Images/ServiceGrid/hvac_service.jpg";
import oil_gas_service from "../Assets/Images/ServiceGrid/oil_gas_service.jpg";
import rental_service from "../Assets/Images/ServiceGrid/rental_service.jpg";
import panel_board_service from "../Assets/Images/ServiceGrid/panel_board_service.jpg";
import manpower_service from "../Assets/Images/ServiceGrid/manpower_service.jpg";
import fabrication_welding_service from "../Assets/Images/ServiceGrid/fabrication_welding_service.jpg";
import cctv_security_service from "../Assets/Images/ServiceGrid/cctv_security_service.jpg";
import office_renovation_service from "../Assets/Images/ServiceGrid/office_renovation_service.jpg";
import maintenance_service from "../Assets/Images/ServiceGrid/maintenance_service.jpg";
import porta_cabin_service from "../Assets/Images/ServiceGrid/porta_cabin_service.jpg";
import global_sourcing_service from "../Assets/Images/ServiceGrid/global_sourcing.jpg";
import { Link } from "react-router-dom";

function ServicesGrid() {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  useEffect(() => {
    const images = [
      civil_construction,
      hvac_service,
      oil_gas_service,
      rental_service,
      panel_board_service,
      manpower_service,
      fabrication_welding_service,
      cctv_security_service,
      office_renovation_service,
      global_sourcing_service,
      porta_cabin_service,
      maintenance_service,
    ];

    let loadedImages = 0;

    images.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        loadedImages++;
        if (loadedImages === images.length) {
          setImagesLoaded(true);
        }
      };
    });
  }, []);

  if (!imagesLoaded) {
    return (
      <div class="loader">
        <div class="circle">
          <div class="dot"></div>
          <div class="outline"></div>
        </div>
        <div class="circle">
          <div class="dot"></div>
          <div class="outline"></div>
        </div>
        <div class="circle">
          <div class="dot"></div>
          <div class="outline"></div>
        </div>
        <div class="circle">
          <div class="dot"></div>
          <div class="outline"></div>
        </div>
      </div>
    );
  }
  return (
    <div className="services-main-container">
      <div className="services-main-title">
        <h1>Our Services</h1>
      </div>
      <div className="service-main-content">
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <div className="service-card">
              <div className="card-image">
                <img src={rental_service} alt="Service Image" />
                <div className="service-details">
                  <span className="service-name">
                    Equipment Rental Services
                  </span>
                  <Link
                    to="/services/equipment-rental-service"
                    className="read-more-btn"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div className="service-card">
              <div className="card-image">
                <img src={global_sourcing_service} alt="Service Image" />
                <div className="service-details">
                  <span className="service-name">
                    Global Sourcing Solutions
                  </span>
                  <Link
                    to="/services/global-sourcing-solution"
                    className="read-more-btn"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div className="service-card">
              <div className="card-image">
                <img src={manpower_service} alt="Service Image" />
                <div className="service-details">
                  <span className="service-name">Manpower Services</span>
                  <Link
                    to="/services/manpower-service"
                    className="read-more-btn"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-4">
            <div className="service-card">
              <div className="card-image">
                <div className="image-container">
                  <img src={civil_construction} alt="Service Image" />
                </div>
                <div className="service-details">
                  <span className="service-name">Civil Works</span>
                  <Link to="/services/civil-works" className="read-more-btn">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div className="service-card">
              <div className="card-image">
                <img src={oil_gas_service} alt="Service Image" />
                <div className="service-details">
                  <span className="service-name">Oil & Gas Services</span>
                  <Link
                    to="/services/oil-gas-service"
                    className="read-more-btn"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div className="service-card">
              <div className="card-image">
                <img src={office_renovation_service} alt="Service Image" />
                <div className="service-details">
                  <span className="service-name">
                    Office renovation & Refurbishment
                  </span>
                  <Link
                    to="/services/renovation-refurbishment-service"
                    className="read-more-btn"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <div className="service-card">
              <div className="card-image">
                <img src={cctv_security_service} alt="Service Image" />
                <div className="service-details">
                  <span className="service-name">
                    Security & Access Control System
                  </span>
                  <Link
                    to="/services/security-system-service"
                    className="read-more-btn"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div className="service-card">
              <div className="card-image">
                <img src={fabrication_welding_service} alt="Service Image" />
                <div className="service-details">
                  <span className="service-name">
                    Fabrication Welding Crack Joint
                  </span>
                  <Link
                    to="/services/fabrication-welding-service"
                    className="read-more-btn"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div className="service-card">
              <div className="card-image">
                <img src={hvac_service} alt="Service Image" />
                <div className="service-details">
                  <span className="service-name">HVAC System Services</span>
                  <Link
                    to="/services/hvac-system-service"
                    className="read-more-btn"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <div className="service-card">
              <div className="card-image">
                <img src={porta_cabin_service} alt="Service Image" />
                <div className="service-details">
                  <span className="service-name">
                    Container Porta Cabin Works
                  </span>
                  <Link
                    to="/services/porta-cabin-works"
                    className="read-more-btn"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-4">
            <div className="service-card">
              <div className="card-image">
                <img src={maintenance_service} alt="Service Image" />
                <div className="service-details">
                  <span className="service-name">Maintenance Works</span>
                  <Link
                    to="/services/maintenance-service"
                    className="read-more-btn"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-4">
            <div className="service-card">
              <div className="card-image">
                <img src={panel_board_service} alt="Service Image" />
                <div className="service-details">
                  <span className="service-name">
                    Panel Board Installation & Services
                  </span>
                  <Link
                    to="/services/panel-board-service"
                    className="read-more-btn"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesGrid;
