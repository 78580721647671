import React from 'react'
import '../../Assets/Styles/footer.css'
function Footer() {
  return (
    <div className='footer-main-container'>
        <div className='footer-contact-logo-main'>

        </div>
        <div className='footer-copyright-main'>
        © Copyright 2024 | SilverCreek | All Rights Reserved
        </div>
    </div>
  )
}

export default Footer