import React from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import "../../Assets/Styles/ServiceStyles/ServicePage.css";
import ForkLift1 from "../../Assets/Images/Services/RentalService/forklift1.jpg";
import ForkLift2 from "../../Assets/Images/Services/RentalService/forklift2.jpg";
import DumpTruck1 from "../../Assets/Images/Services/RentalService/dumptruck1.jpg";
import WaterTanker1 from "../../Assets/Images/Services/RentalService/watertanker1.jpg";
import CoasterBus from "../../Assets/Images/Services/RentalService/coasterBus.jpg";
import Excavator1 from "../../Assets/Images/Services/RentalService/excavator1.jpg";
import Excavator2 from "../../Assets/Images/Services/RentalService/excavator2.jpg";
import Excavator3 from "../../Assets/Images/Services/RentalService/excavator3.jpg";
import MobileCrane1 from "../../Assets/Images/Services/RentalService/mobileCranes1.jpg";
import MobileCrane2 from "../../Assets/Images/Services/RentalService/mobileCranes2.jpg";
import BoomTruck1 from "../../Assets/Images/Services/RentalService/boomtruck1.jpg";
import BoomTruck2 from "../../Assets/Images/Services/RentalService/boomtruck2.jpg";
import FlatBedTrailer from "../../Assets/Images/Services/RentalService/flatBedTrailer.jpg";
function EquipmentRentalService() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="service-page-main-container">
        <Breadcrumb className="custom-breadcrumb">
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/services " }}
            className="breadcrumb-item"
          >
            Services
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item active">
            Equipment Rental Service
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="service-page-main-title px-5 pt-1 col-md-12 mb-4">
          <h1>Equipment Rental Service</h1>
        </div>
        <div className="service-page-content-main-container d-flex">
          <div className="service-page-left-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">MOBILE CRANES</h2>
            <p>
              Our mobile cranes are versatile and capable of handling a wide
              range of lifting tasks, from light to heavy-duty operations.
              Whether you need to move large machinery or heavy materials, our
              fleet of cranes is equipped to meet your requirements with
              precision and safety.
            </p>
            <div class="list-container">
              <ul class="single-list-column">
                <li>Cranes 25 Ton</li>
                <li>Cranes 50 Ton</li>
                <li>Cranes 75 Ton</li>
                <li>Cranes 100 Ton</li>
              </ul>
            </div>
          </div>
          <div className="service-page-right-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={MobileCrane1}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={MobileCrane2}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        <div className="horizontal-divider"></div>

        <div className="service-page-content-main-container d-flex">
          <div className="service-page-alt-left-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={ForkLift1}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={ForkLift2}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="service-page-alt-right-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">FORK LIFTS</h2>
            <p>
              Our forklifts are ideal for industrial environments, providing
              reliable performance for lifting and moving heavy loads. We offer
              a range of capacities to suit your specific needs, from compact
              3-ton forklifts to more robust 10-ton models.
            </p>
            <div class="list-container">
              <ul class="single-list-column">
                <li>Fork Lift 3 Ton</li>
                <li>Fork Lift 5 Ton</li>
                <li>Fork Lift 7 Ton</li>
                <li>Fork Lift 10 Ton</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="horizontal-divider"></div>

        <div className="service-page-content-main-container d-flex">
          <div className="service-page-left-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">BOOM TRUCKS</h2>
            <p>
              Our boom trucks are designed for lifting and transporting loads
              efficiently. Whether you require a truck for construction,
              logistics, or other applications, our boom trucks are versatile
              and reliable options for any project.
            </p>
            <div class="list-container">
              <ul class="single-list-column">
                <li>Boom Truck 5 Ton</li>
                <li>Boom Truck 7 Ton</li>
                <li>Boom Truck 10 Ton</li>
              </ul>
            </div>
          </div>
          <div className="service-page-right-container col-md-6 col-sm-12">
            <Carousel className="service-page-main-carousel">
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={BoomTruck1}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={BoomTruck2}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        <div className="horizontal-divider"></div>

        <div className="service-page-content-main-container d-flex">
          <div className="service-page-alt-left-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={CoasterBus}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={FlatBedTrailer}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="service-page-alt-right-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">TRAILERS & BUSES</h2>
            <p>
              For transporting heavy loads or personnel, our trailers and buses
              offer reliability and comfort. Whether it's long-haul transport or
              daily commutes, our vehicles are equipped to handle various
              transportation needs efficiently.
            </p>
            <div class="list-container">
              <ul class="single-list-column">
                <li>Flat Bed Trailer (extendable)</li>
                <li>Low Bed Trailer</li>
                <li>55-seater Bus (AC/Non-AC)</li>
                <li>Coaster 30-seater</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="horizontal-divider"></div>

        <div className="service-page-content-main-container d-flex">
          <div className="service-page-left-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">CIVIL EQUIPMENT</h2>
            <p>
              Our civil equipment range is perfect for construction and
              earthmoving tasks. From excavation to material handling, our
              machines are maintained to the highest standards, ensuring they
              deliver top performance on your site.
            </p>
            <div class="list-container">
              <ul class="single-list-column">
                <li>Excavator</li>
                <li>Wheel Loader</li>
                <li>Back-hoe Loader</li>
              </ul>
            </div>
          </div>
          <div className="service-page-right-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Excavator1}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Excavator2}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Excavator3}
                    alt="Third slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
        <div className="horizontal-divider"></div>
        <div className="service-page-content-main-container d-flex">
          <div className="service-page-alt-left-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={DumpTruck1}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={WaterTanker1}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="service-page-alt-right-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">OTHER EQUIPMENT</h2>
            <p>
              We also provide a wide array of other essential equipment, from
              power generators and welding machines to dump trucks and tower
              lights. Additionally, our fleet of rental cars and pickups is
              available for personal or business use, offering flexibility and
              convenience for your transportation needs.{" "}
            </p>
            <div class="list-container">
              <ul class="single-list-column">
                <li>Generator - 5kw Upto 500kw</li>
                <li>Welding Machines (Lincoln / Miller)</li>
                <li>Air Compressor</li>
                <li>Dump Truck 16cbm to 32cbm</li>
                <li>Diesel / Water Tanker</li>
                <li>Bobcat</li>
                <li>Tower Lights</li>
                <li>Rental Cars - (Sequoia, Fortuner, Prado, Land Cruiser)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="service-page-contact-container p-5 d-flex">
        <div className="service-page-contact-text col-md-7 col-sm-12">
          <h4>Want to Know More About These Services?</h4>
        </div>
        <div className="service-page-contact-button col-md-5 col-sm-12">
          <Link to="/contact-us">
            <button
              class="btn btn-primary service-page-contact-button-text"
              onClick={navigate("/contact-us")}
            >
              Enquiry Here &nbsp;
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default EquipmentRentalService;
