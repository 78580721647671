import './App.css';
import Footer from './Layouts/Footer/Footer';
import Header from './Layouts/Header/Header';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs'
import { Container } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import ContactUs from './Pages/ContactUs';
import ServicesGrid from './Pages/ServicesGrid';
import 'bootstrap/dist/css/bootstrap.min.css';
import CivilContructionService from './Pages/Services/CivilConstructionService';
import OilGasService from './Pages/Services/OilGasService';
import EquipmentRentalService from './Pages/Services/EquipmentRentalService';
import GlobalSourcingService from './Pages/Services/GlobalSourcingService';
import ManpowerService from './Pages/Services/ManpowerService';
import RenovationRefurbishmentService from './Pages/Services/RenovationRefurbishmentService';
import SecuritySystemService from './Pages/Services/SecuritySystemService';
import FabricationWeldingService from './Pages/Services/FabricationWeldingService';
import HVACSystemService from './Pages/Services/HVACSystemService';
import MaintainanceService from './Pages/Services/MaintainanceService';
import PanelBoardService from './Pages/Services/PanelBoardService';
import PortaCabinWorks from './Pages/Services/PortaCabinWorks';

function App() {
  return (
    <div className="App">
      <Header/>
      <Container fluid className='p-0'>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/services" element={<ServicesGrid />} />
          <Route path="/services/civil-works" element={<CivilContructionService />} />
          <Route path="/services/oil-gas-service" element={<OilGasService />} />
          <Route path="/services/equipment-rental-service" element={<EquipmentRentalService />} />
          <Route path="/services/global-sourcing-solution" element={<GlobalSourcingService />} />
          <Route path="/services/manpower-service" element={<ManpowerService />} />
          <Route path="/services/renovation-refurbishment-service" element={<RenovationRefurbishmentService />} />
          <Route path="/services/security-system-service" element={<SecuritySystemService />} />
          <Route path="/services/fabrication-welding-service" element={<FabricationWeldingService />} />
          <Route path="/services/hvac-system-service" element={<HVACSystemService />} />
          <Route path="/services/maintenance-service" element={<MaintainanceService />} />
          <Route path="/services/panel-board-service" element={<PanelBoardService />} />
          <Route path="/services/porta-cabin-works" element={<PortaCabinWorks />} />          
        </Routes>
      </Container>
      <Footer/>
    </div>
  );
}

export default App;
