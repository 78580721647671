import React from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import "../../Assets/Styles/ServiceStyles/ServicePage.css";
import Welding1 from "../../Assets/Images/Services/FabricationService/welding1.jpg";
import Welding2 from "../../Assets/Images/Services/FabricationService/welding2.jpg";
import Welding3 from "../../Assets/Images/Services/FabricationService/welding3.jpg";
import Welding4 from "../../Assets/Images/Services/FabricationService/welding4.jpg";

function FabricationWeldingService() {
  const navigate = useNavigate();
  return (
    <div>
    <div className="service-page-main-container">
      <Breadcrumb className="custom-breadcrumb">
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: "/services " }}
          className="breadcrumb-item"
        >
          Services
        </Breadcrumb.Item>
        <Breadcrumb.Item className="breadcrumb-item active">
          Fabrication & Welding Service
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="service-page-main-title px-5 pt-1 col-md-12 mb-4">
        <h1>Fabrication & Welding Service</h1>
      </div>
      <div className="service-page-content-main-container d-flex">
        <div className="service-page-left-container col-md-6 col-sm-12">
          <h2 className="col-md-12 pt-4 px-2">FABRICATION SERVICES</h2>
          <p>
            Silver Creek excels in the fabrication of raw metals based on
            precise engineering drawings. Our fabrication services are designed
            to meet the specific needs of our clients, ensuring accuracy and
            quality in every project. We handle a variety of materials, with a
            specialization in stainless steel and carbon steel, providing
            customized solutions to suit your requirements.
            <br /> Our skilled team is equipped to manage both on-site
            fabrication and prefabrication tasks, delivering high-quality
            results tailored to your project's specifications. Whether you need
            intricate parts or large-scale components, our fabrication expertise
            ensures durability and performance.
          </p>
        </div>
        <div className="service-page-right-container col-md-6 col-sm-12">
          <Carousel>
            <Carousel.Item>
              <div className="service-page-carousel-image-wrapper">
                <img
                  className="d-block w-100 service-page-carousel-image"
                  src={Welding1}
                  alt="First slide"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="service-page-carousel-image-wrapper">
                <img
                  className="d-block w-100 service-page-carousel-image"
                  src={Welding2}
                  alt="Second slide"
                />
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>

      <div className="horizontal-divider"></div>

      <div className="service-page-content-main-container d-flex">
        <div className="service-page-alt-left-container col-md-6 col-sm-12">
          <Carousel>
            <Carousel.Item>
              <div className="service-page-carousel-alt-image-wrapper">
                <img
                  className="d-block w-100 service-page-carousel-alt-image"
                  src={Welding3}
                  alt="First slide"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="service-page-carousel-alt-image-wrapper">
                <img
                  className="d-block w-100 service-page-carousel-alt-image"
                  src={Welding4}
                  alt="Second slide"
                />
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="service-page-alt-right-container col-md-6 col-sm-12">
          <h2 className="col-md-12 pt-4 px-2">CRACK WELDING SOLUTIONS</h2>
          <p>
            In addition to our fabrication services, Silver Creek provides
            expert crack welding solutions for pipeline services throughout
            Saudi Arabia. Our qualified welders are trained to detect and repair
            cracks with precision, ensuring the structural integrity of your
            pipelines. <br />
            We offer comprehensive crack welding services, addressing both
            urgent repairs and routine maintenance. Our focus on quality and
            reliability means that every weld is executed to the highest
            standards, providing long-lasting and effective solutions. Trust us
            to handle your welding needs with skill and attention to detail,
            ensuring optimal performance and safety.
          </p>
        </div>
      </div>

    </div>
      <div className="service-page-contact-container p-5 d-flex">
        <div className="service-page-contact-text col-md-7 col-sm-12">
          <h4>Want to Know More About These Services?</h4>
        </div>
        <div className="service-page-contact-button col-md-5 col-sm-12">
          <Link to="/contact-us">
            <button
              class="btn btn-primary service-page-contact-button-text"
              onClick={navigate("/contact-us")}
            >
              Enquiry Here &nbsp;
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default FabricationWeldingService;
