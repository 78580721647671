import React from "react";
import Carousel from "react-bootstrap/Carousel";
import HomeBanner1 from "../Assets/Images/Home/homebanner1.svg";
import HomeBanner2 from "../Assets/Images/Home/homebanner2.svg";
import HomeBanner3 from "../Assets/Images/Home/homebanner3.svg";
import RentalMachine from "../Assets/Images/Home/rentalMachine.png";
import IndustrialSupply from "../Assets/Images/Home/industrialSupply.png";
import ManPower from "../Assets/Images/Home/manpower.png";
import CivilWorker from "../Assets/Images/Home/civilWorker.png";
import RenovationRefursbishment from "../Assets/Images/Home/renovation.png";
import OilGas from "../Assets/Images/Home/oilGas.png";
import MaintenanceOps from "../Assets/Images/Home/maintenance.png";
import GlobalProcurement from "../Assets/Images/Home/procurement.png";
import JoinUS from "../Assets/Images/Home/joinUsSC.jpeg";
import SaudiAramcoLogo from "../Assets/Images/Home/ImgSlider/aramcoLogo.png";
import GasLogo from "../Assets/Images/Home/ImgSlider/gasLogo.png";
import SipchemLogo from "../Assets/Images/Home/ImgSlider/sipchemLogo.png";
import SiemensLogo from "../Assets/Images/Home/ImgSlider/siemensLogo.png";
import AbunayyanLogo from "../Assets/Images/Home/ImgSlider/abunayyanLogo.png";
import JgcLogo from "../Assets/Images/Home/ImgSlider/jgcLogo.png";
import NeomLogo from "../Assets/Images/Home/ImgSlider/neomLogo.png";
import AlmuzainLogo from "../Assets/Images/Home/ImgSlider/almuzainLogo.png";
import BoskalisLogo from "../Assets/Images/Home/ImgSlider/boskalisLogo.png";
import AdvancedLogo from "../Assets/Images/Home/ImgSlider/advancedLogo.png";
import HyundiaEnggLogo from "../Assets/Images/Home/ImgSlider/hyundiaEnggLogo.png";
import JoinUsImg from "../Assets/Images/Home/joinus_img.jpg";
import SafetyPlanet from "../Assets/Images/Home/safety_planet.jpg";
import "../Assets/Styles/HomePage.css";
import { Link } from "react-router-dom";
function Home() {
  return (
    <div className="home-main-container">
      <div className="home-carousel-content">
        <Carousel>
          <Carousel.Item>
            <div className="carousel-image-wrapper">
              <img
                className="d-block w-100 carousel-image"
                src={HomeBanner1}
                alt="First slide"
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel-image-wrapper">
              <img
                className="d-block w-100 carousel-image"
                src={HomeBanner3}
                alt="Second slide"
              />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel-image-wrapper">
              <img
                className="d-block w-100 carousel-image"
                src={HomeBanner2}
                alt="Third slide"
              />
            </div>
          </Carousel.Item>
        </Carousel>
      </div>

      <div className="container-fluid p-5 home-about-company">
        <div className="row align-items-center">
          <div className="col-md-6 d-flex home-about-content">
            <div>
              <h2>
                Who are <b>we</b> ?
              </h2>
              <p>
                We <b>Silver Creek Procurement Solutions </b>specialize in the
                material supply for a wide range of industries, including Oil
                and Gas, Petrochemicals, Power Utilities, Water and Wastewater,
                Marine, Mining, and various government sectors such as Defense,
                Education, and Health. Our extensive reach also supports the EWA
                and other critical industries within the Kingdom of Saudi
                Arabia. In a relatively short span, we've established ourselves
                as leaders in our field by consistently delivering on our
                promises and surpassing expectations.
              </p>
              <Link to="/about-us">
                <button
                  class="home-page-main-button"
                  style={{ width: "17rem", marginTop: "1rem" }}
                >
                  <span class="home-button-circle" aria-hidden="true">
                    <span class="home-button-icon home-button-arrow"></span>
                  </span>
                  <span class="home-main-button-text">Know More About Us</span>
                </button>
              </Link>
            </div>
          </div>
          <div
            className="col-md-6 about-us-content-img-container"
            style={{ borderLeft: "0.15rem solid #cecece " }}
          >
            <img
              src={JoinUS}
              className="img-fluid about-us-content-img"
              alt="No Images Available"
            />
          </div>
        </div>
      </div>
      <div className="home-company-flip-cards d-flex">
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <p className="flip-card-title">2500+</p>
              <p>Trusted Suppliers</p>
            </div>
            <div className="flip-card-back">
              {/* <p className="title">BACK</p> */}
              <p className="flip-card-text">
                Over 2500 trusted suppliers, granting us unparalleled access to
                a wide range of products to fulfill our clients requirements
                efficiently and effectively.
              </p>
            </div>
          </div>
        </div>
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <p className="flip-card-title">30+ </p>
              <p>Years Experience</p>
            </div>
            <div className="flip-card-back">
              <p className="flip-card-text">
                We are backed by a team with over 30 years of collective
                experience in the industrial sector.
              </p>
            </div>
          </div>
        </div>
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <p className="flip-card-title">15+</p>
              <p>Services</p>
            </div>
            <div className="flip-card-back">
              <p className="flip-card-text">
                We offers over 15 specialized services across a variety of
                industries, including Oil and Gas, Petrochemicals, Power
                Utilities, and more.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="home-about-services">
        <div className="home-who-are-we-text">
          <h2>Services We Provide</h2>

          {/* <h1>
            <span>Services We Provide</span>
          </h1> */}
        </div>
        {/* <div className="row"> */}
        <div className="row">
          <div class="col-sm-12 col-md-3">
            <div class="home-page-features-item">
              <div class="home-page-features-icon">
                <img height="42px" width="42px" src={RentalMachine} />
              </div>
              <div class="home-page-features-info">
                <h4 class="home-page-feature-title">RENTAL SERVICE</h4>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-3">
            <div class="home-page-features-item">
              <div class="home-page-features-icon">
                <img height="42px" width="42px" src={IndustrialSupply} />
              </div>
              <div class="home-page-features-info">
                <h4 class="home-page-feature-title">INDUSTRIAL SUPPLY</h4>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-3">
            <div class="home-page-features-item">
              <div class="home-page-features-icon">
                <img height="42px" width="42px" src={CivilWorker} />
              </div>
              <div class="home-page-features-info">
                <h4 class="home-page-feature-title">CIVIL WORKS</h4>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-3">
            <div class="home-page-features-item">
              <div class="home-page-features-icon">
                <img height="42px" width="42px" src={OilGas} />
              </div>
              <div class="home-page-features-info">
                <h4 class="home-page-feature-title">OIL & GAS SERVICE</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div class="col-sm-12 col-md-3">
            <div class="home-page-features-item">
              <div class="home-page-features-icon">
                <img height="42px" width="42px" src={ManPower} />
              </div>
              <div class="home-page-features-info">
                <h4 class="home-page-feature-title">MANPOWER SERVICE</h4>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-3">
            <div class="home-page-features-item">
              <div class="home-page-features-icon">
                <img height="42px" width="42px" src={GlobalProcurement} />
              </div>
              <div class="home-page-features-info">
                <h4 class="home-page-feature-title">
                  GLOBAL SOURCING SOLUTION
                </h4>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-3">
            <div class="home-page-features-item">
              <div class="home-page-features-icon">
                <img
                  height="42px"
                  width="42px"
                  src={RenovationRefursbishment}
                />
              </div>
              <div class="home-page-features-info">
                <h4 class="home-page-feature-title">
                  RENOVATION & REFURBISHMENT
                </h4>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-3">
            <div class="home-page-features-item">
              <div class="home-page-features-icon">
                <img height="42px" width="42px" src={MaintenanceOps} />
              </div>
              <div class="home-page-features-info">
                <h4 class="home-page-feature-title">MAINTENANCE & OPERATION</h4>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}

        <Link to="/services">
          {/* <button className="home-more-service-button btn btn-primary">
            <b>Know More About Services</b>
          </button> */}
          <button
            class="home-page-main-button"
            style={{ marginTop: "4rem", marginBottom: "3rem" }}
          >
            <span class="home-button-circle" aria-hidden="true">
              <span class="home-button-icon home-button-arrow"></span>
            </span>
            <span class="home-main-button-text">More About Services</span>
          </button>
        </Link>
      </div>

      <section class="client-slider">
        <h2>Our Valuable Clients</h2>
        <div class="logos">
          <div class="logos-slide">
            <img src={SaudiAramcoLogo} alt="Client Logo" />
            <img src={SiemensLogo} alt="Client Logo" />
            <img src={GasLogo} alt="Client Logo" />
            <img src={HyundiaEnggLogo} alt="Client Logo" />
            <img src={AlmuzainLogo} alt="Client Logo" />
            <img src={SipchemLogo} alt="Client Logo" />
            <img src={AbunayyanLogo} alt="Client Logo" />
            <img src={JgcLogo} alt="Client Logo" />
            <img src={AdvancedLogo} alt="Client Logo" />
            <img src={BoskalisLogo} alt="Client Logo" />
            <img src={NeomLogo} alt="Client Logo" />
          </div>

          <div class="logos-slide">
            <img src={SaudiAramcoLogo} alt="Client Logo" />
            <img src={SiemensLogo} alt="Client Logo" />
            <img src={GasLogo} alt="Client Logo" />
            <img src={HyundiaEnggLogo} alt="Client Logo" />
            <img src={AlmuzainLogo} alt="Client Logo" />
            <img src={SipchemLogo} alt="Client Logo" />
            <img src={AbunayyanLogo} alt="Client Logo" />
            <img src={JgcLogo} alt="Client Logo" />
            <img src={AdvancedLogo} alt="Client Logo" />
            <img src={BoskalisLogo} alt="Client Logo" />
            <img src={NeomLogo} alt="Client Logo" />
          </div>
        </div>
      </section>
      <div className="home-joinus-main">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-6 d-flex home-safety-content">
              <div>
                <h2>
                  <b>Join Us</b> in Building a Brighter Future
                </h2>
                <p>
                  <b>Join us</b> at Silver Creek Procurement Solutions, where
                  excellence is not just a goal; it's our standard operating
                  procedure. Let's build a brighter future together. By
                  partnering with us, you're not just choosing a service
                  provider; you're choosing a dedicated team committed to
                  driving innovation, ensuring safety, and exceeding
                  expectations. Whether it's through our expertise in global
                  sourcing, equipment rental, or project execution, we're here
                  to support your success every step of the way. Let's shape a
                  future of excellence, together.
                </p>
                <Link to="/contact-us">
                  <button
                    class="home-page-main-button"
                    style={{ width: "14rem", marginTop: "0.5rem" }}
                  >
                    <span class="home-button-circle" aria-hidden="true">
                      <span class="home-button-icon home-button-arrow"></span>
                    </span>
                    <span class="home-main-button-text">Connect with us</span>
                  </button>
                </Link>
              </div>
            </div>
            <div className="col-md-6 p-5">
              <img
                className="img-fluid home-joinus-img"
                src={JoinUsImg}
                alt="Join Us"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="home-safety-main container-fluid">
        <div className="row align-items-center">
          <div className="col-md-5 ">
            <img
              className="img-fluid home-safety-img"
              src={SafetyPlanet}
              alt="Safety Planet"
            />
          </div>
          <div className="col-md-6 d-flex align-items-center home-safety-content">
            <div>
              <h2>
                Unwavering Commitment to <b>Excellence</b> and <b>Safety</b>
              </h2>
              <p>
                At Silver Creek, our commitment is unwavering. We strive
                tirelessly to maintain the high standards we've set, always
                pushing ourselves to go further and do better. Safety and
                quality are not just buzzwords for us; they're the cornerstones
                of everything we do. With a relentless focus on excellence, we
                ensure that every project meets the most rigorous safety
                protocols and quality checks. From our dedicated workforce to
                our advanced safety systems, we leave no stone unturned in
                protecting our people, our clients, and the environment. We
                believe that excellence is a journey, and we're continuously
                evolving to set new benchmarks in the industry.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
