import React from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import "../../Assets/Styles/ServiceStyles/ServicePage.css";
import Panel1 from "../../Assets/Images/Services/PanelBoardService/panelBoard1.jpg";
import Panel2 from "../../Assets/Images/Services/PanelBoardService/panelBoard2.jpg";
import Panel3 from "../../Assets/Images/Services/PanelBoardService/panelBoard3.jpg";
import Panel4 from "../../Assets/Images/Services/PanelBoardService/panelBoard4.jpg";

function PanelBoardService() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="service-page-main-container">
        <Breadcrumb className="custom-breadcrumb">
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/services " }}
            className="breadcrumb-item"
          >
            Services
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item active">
            Panel Board Service
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="service-page-main-title px-5 pt-1 col-md-12 mb-4">
          <h1>Panel Board Service</h1>
        </div>
        <div className="service-page-content-main-container d-flex">
          <div className="service-page-left-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">
              ELECTRICAL PANEL BOARD INSTALLATION & MODIFICATION
            </h2>
            <p>
              Silver Creek specializes in the supply, modification, and
              installation of both high and low voltage electrical panel boards.
              Our services are designed to meet the unique needs of each
              project, ensuring seamless integration with existing systems and
              adherence to the highest industry standards. Whether you need a
              new installation or modifications to an existing setup, our team
              of experienced technicians ensures precision and reliability in
              every task.
            </p>
          </div>
          <div className="service-page-right-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Panel1}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Panel2}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        <div className="horizontal-divider"></div>

        <div className="service-page-content-main-container d-flex">
          <div className="service-page-alt-left-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={Panel4}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={Panel3}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="service-page-alt-right-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">
              SAFETY-DRIVEN HT & LT ELECTRICAL WORKS
            </h2>
            <p>
              At Silver Creek, safety is not just a priority—it's a core value.
              We provide comprehensive HT (High Tension) and LT (Low Tension)
              electrical works, including cable laying services, with a
              steadfast commitment to protecting our employees, clients, and the
              environment. We continually evaluate and enhance our safety
              systems, ensuring all work is performed under the safest
              conditions, minimizing risk, and maintaining the integrity of the
              client's plant and infrastructure.
            </p>
          </div>
        </div>
      </div>
      <div className="service-page-contact-container p-5 d-flex">
        <div className="service-page-contact-text col-md-7 col-sm-12">
          <h4>Want to Know More About These Services?</h4>
        </div>
        <div className="service-page-contact-button col-md-5 col-sm-12">
          <Link to="/contact-us">
            <button
              class="btn btn-primary service-page-contact-button-text"
              onClick={navigate("/contact-us")}
            >
              Enquiry Here &nbsp;
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PanelBoardService;
