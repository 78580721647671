import React from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import "../../Assets/Styles/ServiceStyles/ServicePage.css";
import OilGas1 from "../../Assets/Images/Services/OilGasService/oilGas1.jpg";
import OilGas2 from "../../Assets/Images/Services/OilGasService/oilGas2.jpg";
import OilGas3 from "../../Assets/Images/Services/OilGasService/oilGas3.jpg";
import OilGas4 from "../../Assets/Images/Services/OilGasService/oilGas4.jpg";

function OilGasService() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="service-page-main-container">
        <Breadcrumb className="custom-breadcrumb">
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/services " }}
            className="breadcrumb-item"
          >
            Services
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item active">
            Oil & Gas Service
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="service-page-main-title px-5 pt-1 col-md-12 mb-4">
          <h1>Oil & Gas Service</h1>
        </div>
        <div className="service-page-content-main-container d-flex">
          <div className="service-page-left-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">
              COMPREHENSIVE OIL & GAS SERVICES
            </h2>
            <p>
              Silver Creek provides a broad spectrum of services tailored to the
              needs of the oil and gas industry, both onshore and offshore. Our
              expertise spans a wide range of sectors, serving clients across
              the MENA region with top-quality industrial services. We are
              dedicated to delivering comprehensive solutions that support the
              diverse needs of the oil, gas, and commercial industries.
            </p>
            <p>
              <strong>Our key services include:</strong>
            </p>
            <div class="list-container">
              <ul class="single-list-column">
                <li>
                  <strong>Civil Construction Services:</strong> We offer expert
                  civil construction solutions, including site preparation,
                  structural works, and facility construction, ensuring
                  compliance with industry standards and project specifications.
                </li>
                <li>
                  <strong>Heavy Equipment Rentals:</strong> Our fleet of heavy
                  equipment is available for rental, providing reliable and
                  high-performance machinery for various oil and gas operations.
                  We ensure that all equipment is well-maintained and ready for
                  use.
                </li>
                <li>
                  <strong>Skilled Manpower Supply:</strong> We provide highly
                  skilled personnel to meet the labor requirements of your
                  projects. Our workforce includes experienced engineers,
                  technicians, and laborers who are trained to handle the
                  complexities of the oil and gas sector.
                </li>
              </ul>
            </div>
          </div>
          <div className="service-page-right-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={OilGas1}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={OilGas2}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        <div className="horizontal-divider"></div>

        <div className="service-page-content-main-container d-flex">
          <div className="service-page-alt-left-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={OilGas3}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={OilGas4}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="service-page-alt-right-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">
              TAILORED SOLUTIONS FOR ONSHORE & OFFSHORE PROJECTS
            </h2>
            <p>
              Silver Creek is committed to delivering specialized solutions for
              both onshore and offshore oil and gas projects. Our services are
              designed to meet the unique challenges of each environment,
              ensuring that your operations run smoothly and efficiently.
            </p>
            <p>
              <strong>Key areas of our tailored solutions include:</strong>
            </p>
            <div class="list-container">
              <ul class="single-list-column">
                <li>
                  <strong>Onshore Services:</strong> We offer end-to-end support
                  for onshore projects, including construction, equipment
                  rental, and workforce management. Our services are designed to
                  enhance project efficiency and safety.
                </li>
                <li>
                  <strong>Offshore Services:</strong> Our offshore solutions
                  include specialized construction, equipment, and manpower
                  services tailored to the demanding conditions of the offshore
                  environment. We prioritize safety and compliance with maritime
                  regulations.
                </li>
              </ul>
            </div>
            <p>
              By leveraging our extensive experience and resources, we provide
              solutions that meet the highest standards of quality and
              reliability, ensuring successful project outcomes in both onshore
              and offshore settings.
            </p>
          </div>
        </div>
      </div>
      <div className="service-page-contact-container p-5 d-flex">
        <div className="service-page-contact-text col-md-7 col-sm-12">
          <h4>Want to Know More About These Services?</h4>
        </div>
        <div className="service-page-contact-button col-md-5 col-sm-12">
          <Link to="/contact-us">
            <button
              class="btn btn-primary service-page-contact-button-text"
              onClick={navigate("/contact-us")}
            >
              Enquiry Here &nbsp;
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default OilGasService;
