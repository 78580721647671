import React from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import "../../Assets/Styles/ServiceStyles/ServicePage.css";
import Security1 from "../../Assets/Images/Services/SecurityAccessService/security1.jpg";
import Security2 from "../../Assets/Images/Services/SecurityAccessService/security2.jpg";
import Security3 from "../../Assets/Images/Services/SecurityAccessService/security3.jpg";
import Security4 from "../../Assets/Images/Services/SecurityAccessService/security4.jpg";

function SecuritySystemService() {
  const navigate = useNavigate();
  return (
    <div>
    <div className="service-page-main-container">
      <Breadcrumb className="custom-breadcrumb">
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: "/services " }}
          className="breadcrumb-item"
        >
          Services
        </Breadcrumb.Item>
        <Breadcrumb.Item className="breadcrumb-item active">
          Security & Access Control System
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="service-page-main-title px-5 pt-1 col-md-12 mb-4">
        <h1>Security & Access Control System</h1>
      </div>
      <div className="service-page-content-main-container d-flex">
        <div className="service-page-left-container col-md-6 col-sm-12">
          <h2 className="col-md-12 pt-4 px-2">
            COMPREHENSIVE CCTV SECURITY SOLUTIONS
          </h2>
          <p>
            At Silver Creek, we offer a range of CCTV security packages tailored
            to meet diverse needs, from straightforward camera installations to
            comprehensive surveillance monitoring. Our services include both
            personal and corporate surveillance solutions, ensuring that your
            property and assets are well-protected.
            <br /> Our experienced team of CCTV security technicians, supported
            by industry specialists, will guide you through every stage of the
            process. We handle everything from initial consultation and system
            design to installation and ongoing maintenance. Whether you require
            basic surveillance or advanced monitoring solutions, we are
            committed to providing high-quality protection and peace of mind.
          </p>
        </div>
        <div className="service-page-right-container col-md-6 col-sm-12">
          <Carousel>
            <Carousel.Item>
              <div className="service-page-carousel-image-wrapper">
                <img
                  className="d-block w-100 service-page-carousel-image"
                  src={Security1}
                  alt="First slide"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="service-page-carousel-image-wrapper">
                <img
                  className="d-block w-100 service-page-carousel-image"
                  src={Security2}
                  alt="Second slide"
                />
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>

      <div className="horizontal-divider"></div>

      <div className="service-page-content-main-container d-flex">
        <div className="service-page-alt-left-container col-md-6 col-sm-12">
          <Carousel>
            <Carousel.Item>
              <div className="service-page-carousel-alt-image-wrapper">
                <img
                  className="d-block w-100 service-page-carousel-alt-image"
                  src={Security3}
                  alt="First slide"
                />
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="service-page-carousel-alt-image-wrapper">
                <img
                  className="d-block w-100 service-page-carousel-alt-image"
                  src={Security4}
                  alt="Second slide"
                />
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="service-page-alt-right-container col-md-6 col-sm-12">
          <h2 className="col-md-12 pt-4 px-2">
            ADVANCED ACCESS CONTROL SYSTEM
          </h2>
          <p>
            Silver Creek provides cutting-edge biometric identity solutions and
            access control systems designed to enhance security and streamline
            operations. Our offerings include a wide range of access control
            technologies, such as door intercoms, card and proximity tokens, as
            well as biometric and facial recognition systems.
            <br /> We specialize in designing and installing access control
            systems that best fit your specific requirements. Our solutions are
            known for their industry-leading performance, reliability, and ease
            of integration. By choosing our services, you can ensure effective
            management of access to your premises, focusing on safeguarding your
            people, property, and assets with confidence.
          </p>
        </div>
      </div>

    </div>
      <div className="service-page-contact-container p-5 d-flex">
        <div className="service-page-contact-text col-md-7 col-sm-12">
          <h4>Want to Know More About These Services?</h4>
        </div>
        <div className="service-page-contact-button col-md-5 col-sm-12">
          <Link to="/contact-us">
            <button
              class="btn btn-primary service-page-contact-button-text"
              onClick={navigate("/contact-us")}
            >
              Enquiry Here &nbsp;
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SecuritySystemService;
