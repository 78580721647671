import React from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import "../../Assets/Styles/ServiceStyles/ServicePage.css";
import Manpower1 from "../../Assets/Images/Services/ManpowerService/manpower1.jpg";
import Manpower2 from "../../Assets/Images/Services/ManpowerService/manpower2.jpg";
import Manpower3 from "../../Assets/Images/Services/ManpowerService/manpower3.jpg";
import Manpower4 from "../../Assets/Images/Services/ManpowerService/manpower4.jpg";

function ManpowerService() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="service-page-main-container">
        <Breadcrumb className="custom-breadcrumb">
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/services " }}
            className="breadcrumb-item"
          >
            Services
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item active">
            Manpower Service
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="service-page-main-title px-5 pt-1 col-md-12 mb-4">
          <h1>Manpower Service</h1>
        </div>
        <div className="service-page-content-main-container d-flex">
          <div className="service-page-left-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">SKILLED WORKFORCE</h2>
            <p>
              At Silver Creek, we pride ourselves on our extensive and highly
              skilled workforce. Our team comprises young, dynamic, and
              qualified professionals ready to meet diverse project needs. We
              ensure that our manpower is not only capable but also
              well-equipped to handle various tasks across different sectors.
            </p>
            <p>Our skilled workforce includes:</p>
            <div class="list-container">
              <ul ul class="single-list-column">
                <li>QA / QC Managers (Aramco & Non-Aramco)</li>
                <li>QA / QC Supervisors (Aramco & Non-Aramco)</li>
                <li>QC Inspectors (Aramco & Non-Aramco)</li>
                <li>Engineers</li>
                <li>Designers</li>
                <li>Procurement Engineers / Expeditors</li>
                <li>Material Controllers</li>
                <li>Work Permit Receivers (Aramco & Non-Aramco)</li>
                <li>
                  Safety Managers / Supervisors / Officers (Aramco & Non-Aramco)
                </li>
                <li>Foreman</li>
                <li>Welders (CS/SS)</li>
                <li>Electrical & Instrument Technicians</li>
                <li>Rigger I / II / III (Aramco & Non-Aramco)</li>
                <li>
                  Operators (Crane and other heavy equipment) (Aramco &
                  Non-Aramco)
                </li>
                <li>Fire Watch</li>
                <li>Drivers (Heavy & Light)</li>
                <li>Steel Fixers / Cable Pullers</li>
                <li>Mason</li>
                <li>Carpenter Foreman / Carpenter</li>
                <li>Plumber Foreman / Plumber</li>
                <li>Skilled / Semi Skilled & Unskilled Workers</li>
              </ul>
            </div>
          </div>
          <div className="service-page-right-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Manpower1}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Manpower2}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        <div className="horizontal-divider"></div>

        <div className="service-page-content-main-container d-flex">
          <div className="service-page-alt-left-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={Manpower3}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={Manpower4}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="service-page-alt-right-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">COMPREHENSIVE COVERAGE</h2>
            <p>
              Our manpower services are designed to provide comprehensive
              coverage across various project requirements. Whether you need
              specialized expertise or general labor, Silver Creek ensures that
              all aspects of your project are supported by our qualified
              personnel. From high-level management to hands-on technical
              support, we have the right people for the job.
            </p>
            <p>We offer:</p>
            <div class="list-container">
              <ul class="single-list-column">
                <li>Technical and Engineering Expertise</li>
                <li>Safety and Compliance Assurance</li>
                <li>Efficient Project Management</li>
                <li>On-site and Off-site Labor Solutions</li>
                <li>Flexible Staffing to Meet Project Demands</li>
              </ul>
            </div>
            <p>
              By providing a blend of experienced professionals and adaptable
              workforce solutions, Silver Creek ensures that every project is
              executed with precision, efficiency, and excellence.
            </p>
          </div>
        </div>
      </div>
      <div className="service-page-contact-container p-5 d-flex">
        <div className="service-page-contact-text col-md-7 col-sm-12">
          <h4>Want to Know More About These Services?</h4>
        </div>
        <div className="service-page-contact-button col-md-5 col-sm-12">
          <button
            class="btn btn-primary service-page-contact-button-text"
            onClick={navigate("/contact-us")}
          >
            Enquiry Here &nbsp;
          </button>
        </div>
      </div>
    </div>
  );
}

export default ManpowerService;
