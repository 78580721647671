import React from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import "../../Assets/Styles/ServiceStyles/ServicePage.css";
import Excavation1 from "../../Assets/Images/Services/CivilConstructionService/excavation1.jpg";
import Excavation2 from "../../Assets/Images/Services/CivilConstructionService/excavation2.jpg";
import Excavation3 from "../../Assets/Images/Services/CivilConstructionService/excavation3.jpg";
import Fence1 from "../../Assets/Images/Services/CivilConstructionService/fence1.jpg";
import Fence2 from "../../Assets/Images/Services/CivilConstructionService/fence2.jpg";
import Fence3 from "../../Assets/Images/Services/CivilConstructionService/fence3.jpg";
import Asphalt1 from "../../Assets/Images/Services/CivilConstructionService/asphalt1.jpg";
import Asphalt2 from "../../Assets/Images/Services/CivilConstructionService/asphalt2.jpg";
import Asphalt3 from "../../Assets/Images/Services/CivilConstructionService/asphalt3.jpg";

function CivilContructionService() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="service-page-main-container">
        <Breadcrumb className="custom-breadcrumb">
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/services " }}
            className="breadcrumb-item"
          >
            Services
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item active">
            Civil Works
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="service-page-main-title px-5 pt-1 col-md-12 mb-4">
          <h1>Civil Works</h1>
        </div>
        <div className="service-page-content-main-container d-flex">
          <div className="service-page-left-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">
              EXCAVATION & BACKFILLING WORKS
            </h2>
            <p>
              Silver Creek provides with excavation services to our clients as
              per their given design and designated area. When necessary, we
              also do some backfilling work for our clients for best results. We
              make sure we follow the protocols and safety measures as per the
              Saudi Arabian standards. Furthermore, we guarantee the provision
              of services in a realistic and accident-free manner. Silver creek
              has a fleet of heavy earthmoving types of equipment with
              well-experienced operators able to carry out all kinds of
              earthworks including rock cutting and embankment preparation in
              any kind of terrain.
            </p>
          </div>
          <div className="service-page-right-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Excavation1}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Excavation2}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Excavation3}
                    alt="Third slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        <div className="horizontal-divider"></div>

        <div className="service-page-content-main-container d-flex">
          <div className="service-page-alt-left-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={Asphalt1}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={Asphalt2}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={Asphalt3}
                    alt="Third slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="service-page-alt-right-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">ASPHALT WORKS</h2>
            <p>
              Silver creek provides services that range from hand to machine
              lay. We can lay any solution of Tarmac/Asphalt materials ranging
              from Domestic Driveways, Carparks, Council Adoptable Roads and
              Footpaths. We also undertake major Civils and Infrastructure
              projects.
            </p>
          </div>
        </div>
        <div className="horizontal-divider"></div>

        <div className="service-page-content-main-container d-flex">
          <div className="service-page-left-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">FENCING WORKS</h2>
            <p>
              Silver creek provides you with powerful security Fencing made with
              Galvanized zinc and aluminium. VRP providing high Power Reliable
              security fencing. this security fencing was looked hard on the
              attacking side.Tamper-proof was attached to the fixings so that
              the fence panel cannot be removed from the attack.
            </p>
          </div>
          <div className="service-page-right-container col-md-6 col-sm-12">
            <Carousel className="service-page-main-carousel">
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Fence1}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Fence2}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Fence3}
                    alt="Third slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
      <div className="service-page-contact-container p-5 d-flex">
        <div className="service-page-contact-text col-md-7 col-sm-12">
          <h4>Want to Know More About These Services?</h4>
        </div>
        <div className="service-page-contact-button col-md-5 col-sm-12">
          <Link to="/contact-us">
            <button
              class="btn btn-primary service-page-contact-button-text"
              onClick={navigate("/contact-us")}
            >
              Enquiry Here &nbsp;
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CivilContructionService;
