import React from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import "../../Assets/Styles/ServiceStyles/ServicePage.css";
import GlobalSourcing1 from "../../Assets/Images/Services/GlobalSourcing/globalsourcing1.jpg";
import GlobalSourcing2 from "../../Assets/Images/Services/GlobalSourcing/globalsourcing2.jpg";
import GlobalSourcing3 from "../../Assets/Images/Services/GlobalSourcing/globalsourcing3.jpg";
import GlobalSourcing4 from "../../Assets/Images/Services/GlobalSourcing/globalsourcing4.jpg";

function GlobalSourcingService() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="service-page-main-container">
        <Breadcrumb className="custom-breadcrumb">
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/services " }}
            className="breadcrumb-item"
          >
            Services
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item active">
            Global Sourcing Service
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="service-page-main-title px-5 pt-1 col-md-12 mb-4">
          <h1>Global Sourcing Service</h1>
        </div>
        <div className="service-page-content-main-container d-flex">
          <div className="service-page-left-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">
              GLOBAL SOURCING: OPTIMIZING PROCUREMENT ACROSS INTERNATIONAL
              MARKETS
            </h2>
            <p>
              Global sourcing is a strategic approach that enables businesses to
              procure goods and services from international markets, crossing
              geopolitical boundaries to leverage cost efficiencies. By tapping
              into the vast potential of global markets, companies can access
              high-quality raw materials, specialized products, and skilled
              labor at competitive prices. This strategy not only helps in
              reducing overall procurement costs but also enhances the supply
              chain by incorporating diverse resources from low-cost countries.
              At Silver Creek, we specialize in navigating the complexities of
              global sourcing, ensuring that our clients receive the best value
              without compromising on quality or compliance.
            </p>
          </div>
          <div className="service-page-right-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={GlobalSourcing1}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={GlobalSourcing2}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        <div className="horizontal-divider"></div>

        <div className="service-page-content-main-container d-flex">
          <div className="service-page-alt-left-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={GlobalSourcing3}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={GlobalSourcing4}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="service-page-alt-right-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">
              TARGETED PROCUREMENT STRATEGIES
            </h2>
            <p>
              Silver Creek goes beyond generic procurement by focusing on
              specialized tools and shutdown projects. Our procurement
              strategies are meticulously designed to meet the unique demands of
              industrial operations, particularly in time-sensitive environments
              like shutdowns. We source and deliver the exact tools and
              equipment required to ensure seamless execution of these critical
              projects, minimizing downtime and maximizing efficiency. With our
              global reach and industry expertise, we ensure that your project
              needs are met with precision, on time, and within budget, no
              matter where in the world they arise.
            </p>
          </div>
        </div>
      </div>
      <div className="service-page-contact-container  mt-5 p-5 d-flex">
        <div className="service-page-contact-text col-md-7 col-sm-12">
          <h4>Want to Know More About These Services?</h4>
        </div>
        <div className="service-page-contact-button col-md-5 col-sm-12">
          <Link to="/contact-us">
            <button
              class="btn btn-primary service-page-contact-button-text"
              onClick={navigate("/contact-us")}
            >
              Enquiry Here &nbsp;
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default GlobalSourcingService;
