import React from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import "../../Assets/Styles/ServiceStyles/ServicePage.css";
import Hvac1 from "../../Assets/Images/Services/HvacService/hvac1.jpg";
import Hvac2 from "../../Assets/Images/Services/HvacService/hvac2.jpg";
import Hvac3 from "../../Assets/Images/Services/HvacService/hvac3.jpg";
import Hvac4 from "../../Assets/Images/Services/HvacService/hvac4.jpg";
import Hvac5 from "../../Assets/Images/Services/HvacService/hvac5.jpg";
import Hvac6 from "../../Assets/Images/Services/HvacService/hvac6.jpg";

function HVACSystemService() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="service-page-main-container">
        <Breadcrumb className="custom-breadcrumb">
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/services " }}
            className="breadcrumb-item"
          >
            Services
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item active">
            HVAC System Service
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="service-page-main-title px-5 pt-1 col-md-12 mb-4">
          <h1>HVAC System Service</h1>
        </div>
        <div className="service-page-content-main-container d-flex">
          <div className="service-page-left-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">
              EXPERT HVAC DESIGN & INSTALLATION
            </h2>
            <p>
              Silver Creek excels in delivering tailored HVAC solutions,
              including the design, selection, and installation of advanced air
              conditioning and industrial refrigeration systems. Our expertise
              covers both commercial and residential projects. We ensure that
              every installation meets the highest standards of quality and
              efficiency.
              <p>Key aspects of our design and installation process include:</p>
            </p>

            <div class="list-container">
              <ul class="single-list-column">
                <li>
                  <b>Needs Assessment:</b> We conduct thorough consultations to
                  understand your unique requirements and provide solutions that
                  best fit your space and usage.
                </li>

                <li>
                  <b>System Design:</b> Our expert engineers design HVAC systems
                  that balance comfort, efficiency, and cost-effectiveness. We
                  utilize advanced software and techniques to model and plan the
                  ideal system configuration.
                </li>
                <li>
                  <b>Professional Installation:</b> Our certified technicians
                  execute installations with precision, following industry
                  standards and manufacturer guidelines. We ensure that all
                  components are correctly fitted and calibrated for optimal
                  performance.
                </li>
                <li>
                  <b>Quality Assurance:</b> All installations are subject to
                  rigorous quality checks and testing to verify that the system
                  operates as intended and meets all safety and regulatory
                  standards.
                </li>
              </ul>
            </div>
          </div>
          <div className="service-page-right-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Hvac1}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Hvac2}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        <div className="horizontal-divider"></div>

        <div className="service-page-content-main-container d-flex">
          <div className="service-page-alt-left-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={Hvac3}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={Hvac4}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="service-page-alt-right-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">
              COMPREHENSIVE MAINTENANCE & REPAIR
            </h2>
            <p>
              Our comprehensive HVAC maintenance and repair services are
              designed to ensure the optimal performance and longevity of your
              systems. We conduct regular maintenance and swift repairs, all
              under the vigilant supervision of qualified Site Engineers and
              Project Managers. Each service operation is meticulously
              documented and monitored to address any issues promptly and
              effectively, ensuring compliance with all contractual
              specifications and quality standards.
            </p>
            <p>Our comprehensive maintenance and repair services include:</p>
            <div class="list-container">
              <ul class="single-list-column">
                <li>Routine Maintenance</li>
                <li>Emergency Repairs</li>
                <li>System Analysis</li>
                <li>Compliance & Safety</li>
              </ul>
            </div>
            <p>
              Our goal is to provide you with peace of mind, knowing that your
              HVAC systems are maintained by professionals who prioritize
              efficiency, safety, and quality.
            </p>
          </div>
        </div>
        <div className="horizontal-divider"></div>

        <div className="service-page-content-main-container d-flex">
          <div className="service-page-left-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">
              ADVANCED HVAC SOLUTIONS & EXPERTISE
            </h2>
            <p>
              Silver Creek offers specialized solutions for a wide range of HVAC
              systems, including:
            </p>
            <div class="list-container">
              <ul class="single-list-column">
                <li>
                  <b>Cooling Towers:</b> Efficient cooling solutions for
                  large-scale applications.
                </li>
                <li>
                  <b>Air Cooled Chillers:</b> Reliable and energy-efficient
                  cooling systems.
                </li>
                <li>
                  <b>Absorption Chillers:</b> Advanced systems for industrial
                  and commercial cooling needs.
                </li>
                <li>
                  <b>Steam and Hot Water Boilers:</b> High-performance boilers
                  for various heating requirements.
                </li>
                <li>
                  <b>Air Handling Units:</b> Customizable units for optimal air
                  distribution.
                </li>
                <li>
                  <b>Fan Coil Units:</b> Effective solutions for localized
                  heating and cooling.
                </li>
              </ul>
            </div>
            <p>
              Our team's extensive experience allows us to provide expert advice
              and develop customized solutions that ensure user satisfaction,
              operational continuity, and minimal running costs. We assess your
              specific needs and offer well-rounded options to help you make
              informed decisions.
            </p>
          </div>
          <div className="service-page-right-container col-md-6 col-sm-12">
            <Carousel className="service-page-main-carousel">
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Hvac5}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Hvac6}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
      <div className="service-page-contact-container p-5 d-flex">
        <div className="service-page-contact-text col-md-7 col-sm-12">
          <h4>Want to Know More About These Services?</h4>
        </div>
        <div className="service-page-contact-button col-md-5 col-sm-12">
          <Link to="/contact-us">
            <button
              class="btn btn-primary service-page-contact-button-text"
              onClick={navigate("/contact-us")}
            >
              Enquiry Here &nbsp;
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HVACSystemService;
