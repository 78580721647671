import React from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import "../../Assets/Styles/ServiceStyles/ServicePage.css";
import Maintenance1 from "../../Assets/Images/Services/MaintenanceWorks/maintenance1.jpg";
import Maintenance2 from "../../Assets/Images/Services/MaintenanceWorks/maintenance2.jpg";
import Maintenance3 from "../../Assets/Images/Services/MaintenanceWorks/maintenance3.jpg";
import Maintenance4 from "../../Assets/Images/Services/MaintenanceWorks/maintenance4.jpg";
function MaintainanceService() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="service-page-main-container">
        <Breadcrumb className="custom-breadcrumb">
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/services " }}
            className="breadcrumb-item"
          >
            Services
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item active">
            Maintenance Services
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="service-page-main-title px-5 pt-1 col-md-12 mb-4">
          <h1>Maintenance Service</h1>
        </div>
        <div className="service-page-content-main-container d-flex">
          <div className="service-page-left-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">
              COMPREHENSIVE MAINTENANCE WORKS
            </h2>
            <p>
              Silver Creek offers a full suite of maintenance services, with a
              strong focus on the fabrication of raw metals and expert welding
              services. Our team of skilled professionals works meticulously
              with engineering drawings to ensure precision and durability in
              every project. We excel in crack welding joints for pipeline
              services, utilizing advanced techniques to detect and repair
              faults. Whether it's stainless steel or carbon steel, our welders
              are trained to deliver top-notch results, ensuring structural
              integrity and long-lasting performance.
            </p>
          </div>
          <div className="service-page-right-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Maintenance2}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Maintenance1}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        <div className="horizontal-divider"></div>

        <div className="service-page-content-main-container d-flex">
          <div className="service-page-alt-left-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={Maintenance3}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={Maintenance4}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="service-page-alt-right-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">
              VERSATILE MAINTENANCE SOLUTIONS
            </h2>
            <p>
              Beyond fabrication and welding, Silver Creek is equipped to handle
              a broad array of maintenance tasks. We specialize in on-site
              repairs, offering swift and effective solutions to minimize
              downtime. Our expertise extends to gangway fabrication, ensuring
              safe and reliable access systems for various industrial
              applications. Additionally, we perform bucket repairs, base frame
              construction for spooling devices, and other custom maintenance
              services tailored to your specific needs. Our commitment to
              quality and safety guarantees that all maintenance work is
              executed to the highest standards, regardless of the complexity or
              location.
            </p>
          </div>
        </div>
      </div>
      <div className="service-page-contact-container p-5 d-flex">
        <div className="service-page-contact-text col-md-7 col-sm-12">
          <h4>Want to Know More About These Services?</h4>
        </div>
        <div className="service-page-contact-button col-md-5 col-sm-12">
          <Link to="/contact-us">
            <button
              class="btn btn-primary service-page-contact-button-text"
              onClick={navigate("/contact-us")}
            >
              Enquiry Here &nbsp;
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MaintainanceService;
