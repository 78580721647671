import React from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import "../../Assets/Styles/ServiceStyles/ServicePage.css";
import PortaCabin1 from "../../Assets/Images/Services/PortaCabinWorks/portacabin1.jpg";
import PortaCabin2 from "../../Assets/Images/Services/PortaCabinWorks/portacabin2.jpg";
import PortaCabin3 from "../../Assets/Images/Services/PortaCabinWorks/portacabin3.jpg";
import PortaCabin4 from "../../Assets/Images/Services/PortaCabinWorks/portacabin4.jpg";

function PortaCabinWorks() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="service-page-main-container">
        <Breadcrumb className="custom-breadcrumb">
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/services " }}
            className="breadcrumb-item"
          >
            Services
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item active">
            Porta Cabin Works
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="service-page-main-title px-5 pt-1 col-md-12 mb-4">
          <h1>Porta Cabin Works</h1>
        </div>
        <div className="service-page-content-main-container d-flex">
          <div className="service-page-left-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">
              CUSTOMIZED PORTA CABIN WORKS
            </h2>
            <p>
              Silver Creek specializes in designing and manufacturing porta
              cabins and containers tailored to meet a variety of needs across
              Saudi Arabia. Our porta cabins are crafted to ensure exceptional
              comfort, flexibility, and versatility, and can be customized
              according to your specific requirements.
              <br /> We offer containers in standard sizes of 20ft and 40ft, and
              our porta cabins can be tailored with additional features such as
              curtains, window spaces, shelves, and air conditioners. Whether
              you need a simple workspace or a more elaborate setup, our cabins
              are built to be quickly and easily installed on-site, meeting your
              precise needs with technical excellence.
              <br /> Additionally, our porta cabins can be designed and painted
              in various colors, and manufactured using cladding panels and
              foam-filled sandwich panels. These materials are selected to
              withstand extreme weather conditions, ensuring durability and
              longevity.
            </p>
          </div>
          <div className="service-page-right-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={PortaCabin1}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={PortaCabin2}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        <div className="horizontal-divider"></div>

        <div className="service-page-content-main-container d-flex">
          <div className="service-page-alt-left-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={PortaCabin3}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={PortaCabin4}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="service-page-alt-right-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">VERSATILE STORAGE OPTIONS</h2>
            <p>
              At Silver Creek, we provide a wide range of portable storage
              buildings and prefabricated solutions to cater to diverse
              applications. Our offerings include worksite camps, dining halls,
              meeting rooms, mosques, worker accommodations, and more.
            </p>

            <p>
              Some of the commonly built porta cabins and structures include:
            </p>

            <div class="list-container">
              <ul class="list-column">
                <li>Portable Houses</li>
                <li>Mosques</li>
                <li>Storage Rooms</li>
                <li>Staff Camps</li>
                <li>Mess Halls</li>
                <li>Residential Houses</li>
                <li>Security Offices</li>
                <li>Storage Facilities</li>
                <li>Bathrooms</li>
              </ul>
              <ul class="list-column">
                <li>Pantry</li>
                <li>Site Offices</li>
                <li>Labor Camps</li>
                <li>Restrooms</li>
                <li>Warehouses</li>
                <li>Accommodation Units</li>
                <li>Medical Clinics</li>
                <li>Security Units</li>
                <li>Emergency Cabins</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="service-page-contact-container p-5 d-flex">
        <div className="service-page-contact-text col-md-7 col-sm-12">
          <h4>Want to Know More About These Services?</h4>
        </div>
        <div className="service-page-contact-button col-md-5 col-sm-12">
          <Link to="/contact-us">
            <button
              class="btn btn-primary service-page-contact-button-text"
              onClick={navigate("/contact-us")}
            >
              Enquiry Here &nbsp;
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default PortaCabinWorks;
