import React from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import "../../Assets/Styles/ServiceStyles/ServicePage.css";
import Renovation1 from "../../Assets/Images/Services/RenovationRefurbhismentService/renovation1.jpg";
import Renovation2 from "../../Assets/Images/Services/RenovationRefurbhismentService/renovation2.jpg";
import Renovation3 from "../../Assets/Images/Services/RenovationRefurbhismentService/renovation3.jpg";
import Renovation4 from "../../Assets/Images/Services/RenovationRefurbhismentService/renovation4.jpg";
function RenovationRefurbishmentService() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="service-page-main-container">
        <Breadcrumb className="custom-breadcrumb">
          <Breadcrumb.Item
            linkAs={Link}
            linkProps={{ to: "/services " }}
            className="breadcrumb-item"
          >
            Services
          </Breadcrumb.Item>
          <Breadcrumb.Item className="breadcrumb-item active">
            Renovation & Refurbishment Service
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="service-page-main-title px-5 pt-1 col-md-12 mb-4">
          <h1>Renovation & Refurbishment Service</h1>
        </div>
        <div className="service-page-content-main-container d-flex">
          <div className="service-page-left-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">
              COMPREHENSIVE OFFICE SOLUTIONS
            </h2>
            <p>
              At Silver Creek, we specialize in delivering top-notch solutions
              for all your office needs. Whether you're seeking a modern upgrade
              or a complete transformation, our expertise ensures exceptional
              results. Our services include:
            </p>
            <div class="list-container">
              <ul class="single-list-column">
                <li>
                  <b>Office Remodeling & Renovation:</b> Transform your
                  workspace with our tailored renovation services, including
                  demolition, remodeling, and complete refurbishments.
                </li>

                <li>
                  <b>Masonry & Carpentry:</b> Enhance your office with our
                  skilled masonry and carpentry services, perfect for custom
                  builds and structural changes.
                </li>
                <li>
                  <b>Electrical & Lighting:</b> From power and data cabling to
                  fall ceilings and lighting installation, we handle all aspects
                  of your office's electrical needs.
                </li>
                <li>
                  <b>Glass Wall Paneling & Partitioning:</b> Create a sleek,
                  modern look with our glass wall paneling systems and efficient
                  office partitioning solutions.
                </li>
                <li>
                  <b>Flooring & Painting:</b> Update your space with
                  high-quality laminate or ceramic tile flooring and
                  professional painting services.
                </li>
              </ul>
            </div>
          </div>
          <div className="service-page-right-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Renovation1}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-image"
                    src={Renovation2}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>

        <div className="horizontal-divider"></div>

        <div className="service-page-content-main-container d-flex">
          <div className="service-page-alt-left-container col-md-6 col-sm-12">
            <Carousel>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={Renovation3}
                    alt="First slide"
                  />
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="service-page-carousel-alt-image-wrapper">
                  <img
                    className="d-block w-100 service-page-carousel-alt-image"
                    src={Renovation4}
                    alt="Second slide"
                  />
                </div>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="service-page-alt-right-container col-md-6 col-sm-12">
            <h2 className="col-md-12 pt-4 px-2">
              SPECIALIZED OFFICE REFURBISHMENT SERVICES
            </h2>
            <p>
              Revitalize your office environment with Silver Creek's specialized
              refurbishment services. Our comprehensive approach ensures every
              detail is handled with precision:
            </p>
            <div class="list-container">
              <ul class="single-list-column">
                <li>
                  <b>Demolition of Commercial Spaces:</b> Safely and efficiently
                  clear out existing structures to make way for your new office
                  design.
                </li>

                <li>
                  <b>Fall Ceiling & Gypsum Installation:</b> Achieve a polished
                  finish with our expert installation of fall ceilings and
                  gypsum boards.
                </li>
                <li>
                  <b>Plumbing Work:</b> Ensure smooth operations with our
                  reliable plumbing services tailored for commercial spaces.
                </li>
                <li>
                  <b>Laminate Flooring & Ceramic Tile:</b> Choose from a range
                  of flooring options to match your office's style and
                  functionality.
                </li>
                <li>
                  <b>Painting Work:</b> Refresh your office with a professional
                  paint job that enhances both aesthetics and atmosphere.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="service-page-contact-container p-5 d-flex">
        <div className="service-page-contact-text col-md-7 col-sm-12">
          <h4>Want to Know More About These Services?</h4>
        </div>
        <div className="service-page-contact-button col-md-5 col-sm-12">
          <Link to="/contact-us">
            <button
              class="btn btn-primary service-page-contact-button-text"
              onClick={navigate("/contact-us")}
            >
              Enquiry Here &nbsp;
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default RenovationRefurbishmentService;
